h1,
h2,
h3,
h4,
h5,
h6,
li,
p {
    margin: 0 0 16px;
    font-size: 16px;
}

h1 {
    font-size: 40px;
    line-height: 60px;
}

h1,
h2 {
    font-weight: 700;
}

h2 {
    font-size: 32px;
    line-height: 48px;
}

h3 {
    font-size: 24px;
    line-height: 36px;
}

h3,
h4 {
    font-weight: 700;
}

h4 {
    font-size: 20px;
    line-height: 30px;
}

h5,
h6 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
}

a {
    text-decoration: none;
    cursor: pointer;
    color: #000;
}

a:hover,
a[rel~="nofollow"] {
    text-decoration: underline;
}

a[rel~="nofollow"] {
    color: #553df4;
}

a[rel~="nofollow"]:hover {
    text-decoration: none;
}

.visible {
    display: block;
}

.hidden {
    display: none;
}

.page {
    width: 100%;
}

.container {
    position: relative;
    width: 90%;
    max-width: 1113px;
    background: #fff;
    color: #000;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 28px;
    margin: 0;
}

@media screen and (max-width: 640px) {
    .container {
        margin: 0 auto;
    }
}

.header {
    padding: 16px 0;
}

.header .title {
    font-size: 28px;
    line-height: 42px;
    font-weight: 700;
}

.content {
    padding-bottom: 32px;
}