html {
    margin: 0;
    padding: 0;
    font-size: 10px; 
    font-family: interstate-condensed;
    color: rgba(47, 17, 80, 1);
  }
  body {
    position: fixed; /*for SAFARI*/
    margin: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
  * {
    box-sizing: border-box;
  }

  .bold{
    font-weight: bold;
  }