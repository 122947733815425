a {
    color: #5d88b3;
}

a .icon {
    line-height: 1.428571429;
}

a:hover .icon {
    text-decoration: none;
}

button {
    outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 0 18px 0;
    font-weight: 400;
}

p {
    margin: 0 0 20px 0;
}

strong {
    font-weight: 700;
}